// header-wrapper.component.ts
import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-header-wrapper',
  template: `
    <ng-container *ngComponentOutlet="currentHeaderComponent"></ng-container>
  `
})
export class HeaderWrapperComponent {
  currentHeaderComponent: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute.firstChild),
        map((route) => {
          let currentRoute = route;
          while (currentRoute && currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
          }
          return currentRoute;
        }),
        filter((route) => !!route && route.outlet === 'primary'),
        map((route) => route && route.snapshot.data['headerComponent'])
      )
      .subscribe((component) => {
        if (component) {
          this.currentHeaderComponent = component;
        }
      });
  }
}
