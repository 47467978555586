<div class="header-wrapper">
  <p-toolbar styleClass="custom-toolbar-cover-story">
    <div class="p-toolbar-group-left">
      <div class="logo-container" routerLink="/">
        <img
          src="/assets/images/sports-social-logo.png"
          alt="Logo"
          class="logo"
        />
        <div class="logo-text">
          <div class="logo-title">Chase Your Sport</div>
          <div class="logo-subtitle">Sports Social Blog</div>
        </div>
      </div>
    </div>
    <div class="p-toolbar-group-right do-not-show-in-tab-and-mobile">
      <p>Share:</p>
      <a
        class="toolbar-buttons"
        href="https://www.facebook.com/sharer/sharer.php?u={{ currentUrl }}"
        target="_blank"
      >
        <i class="pi pi-facebook"></i>
      </a>
      <a
        class="toolbar-buttons"
        href="https://twitter.com/share?url={{ currentUrl }}"
        target="_blank"
      >
        <i class="pi pi-twitter"></i>
      </a>
      <a
        class="toolbar-buttons"
        href="https://www.linkedin.com/shareArticle?mini=true&url={{
          currentUrl
        }}&title={{ pageTitle }}&source=chaseyoursport.com"
        target="_blank"
      >
        <i class="pi pi-linkedin"></i>
      </a>
    </div>
  </p-toolbar>
</div>
