import {
  Component,
  OnInit,
  ViewEncapsulation,
  PLATFORM_ID,
  Inject
} from '@angular/core';
import { TrendingTopic } from 'src/app/models/topic.model';
import { AuthService } from '../services/auth.service';
import { SlugifyService } from '../services/slugify.service';
import { NavigationService } from './navigation.service';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.Emulated // this is the default, can be omitted
})
export class NavigationComponent implements OnInit {
  text: string = '';
  sidebarVisible: boolean = false;
  topSnackbarVisible: boolean = true;
  trendingTopics: TrendingTopic[] = [];
  isUserLoggedIn: boolean = false;
  userDetails: any;
  initials: string | null = null;
  dashboardUser: boolean = false;
  userRole: string = 'Reader';
  isBrowser: boolean = false;
  constructor(
    private navigationService: NavigationService,
    private slugifyService: SlugifyService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.loadTrendingTopics();
    this.checkUserLoggedIn();
    this.setupPeriodicRefresh();
  }
  checkUserLoggedIn(): void {
    if (this.isBrowser) {
      const token = localStorage.getItem('auth_token');
      this.isUserLoggedIn = !!token;
      if (this.isUserLoggedIn) {
        this.fetchUserDetails();
      }
    }
  }

  signOut(): void {
    this.authService.deleteToken();
    location.reload();
  }

  fetchUserDetails() {
    this.authService.getUserDetails().subscribe({
      next: (response) => {
        if (response && response.data) {
          this.userDetails = response.data;
          this.dashboardUser = response.dashboardUser;
          this.userRole = response.userRole;
          this.initials = this.getInitials(this.userDetails.name);
        } else {
          console.error('No user details found');
        }
      },
      error: (error) => {
        console.error('Error fetching user details', error);
        if (this.isBrowser) {
          this.authService.deleteToken();
          location.replace('/');
        }
      }
    });
  }

  getInitials(name: string): string {
    return name
      .split(' ')
      .map((n) => n[0])
      .join('')
      .toUpperCase();
  }
  toggleSidebar(): void {
    this.sidebarVisible = !this.sidebarVisible;
  }
  toggleTopSnackbar(): void {
    this.topSnackbarVisible = !this.topSnackbarVisible;
  }
  setupPeriodicRefresh(): void {
    setInterval(() => {
      this.updateTrendingTopicsInBackground();
    }, 3600000); // Refresh every hour (3600000 ms)
  }

  loadTrendingTopics(): void {
    const cachedData = localStorage.getItem('trendingTopicsData');
    const now = new Date().getTime();

    if (cachedData) {
      const { topics, lastUpdateTime } = JSON.parse(cachedData);
      if (now - lastUpdateTime < 3600000) {
        // 1 hour
        this.trendingTopics = topics;
        return;
      } else {
        // Data is outdated but can still be used temporarily
        this.trendingTopics = topics;
        this.updateTrendingTopicsInBackground();
      }
    } else {
      // No cached data, fetch new data immediately
      this.updateTrendingTopics();
    }
  }

  updateTrendingTopicsInBackground(): void {
    const cachedData = localStorage.getItem('trendingTopicsData');
    const now = new Date().getTime();

    if (cachedData) {
      const { topics, lastUpdateTime } = JSON.parse(cachedData);
      if (now - lastUpdateTime >= 3600000) {
        // 1 hour
        this.fetchAndStoreTrendingTopics();
      }
    } else {
      this.fetchAndStoreTrendingTopics();
    }
  }

  fetchAndStoreTrendingTopics(): void {
    this.navigationService.getTrendingTopics().subscribe({
      next: (res) => {
        if (res && res.data) {
          this.trendingTopics = res.data.slice(0, 9); // Limit to 9 items
          const dataToStore = {
            topics: this.trendingTopics,
            lastUpdateTime: new Date().getTime()
          };
          localStorage.setItem(
            'trendingTopicsData',
            JSON.stringify(dataToStore)
          );
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  updateTrendingTopics(): void {
    this.fetchAndStoreTrendingTopics();
  }
  updateImageUrl(oldUrl: string): string {
    // Define the old URL pattern and the new URL pattern
    if (oldUrl) {
      const oldUrlPattern = 'https://chase-your-sport-images.s3.amazonaws.com';
      const newUrlPattern = 'https://static.chaseyoursport.com';

      // Replace the old URL pattern with the new URL pattern in the old URL
      if (oldUrl.startsWith(oldUrlPattern)) {
        return oldUrl.replace(oldUrlPattern, newUrlPattern);
      }

      // If the old URL does not match the pattern, return it unchanged
      return oldUrl;
    }
    return '';
  }

  slugify(input: string): string {
    return this.slugifyService.slugify(input);
  }
}
