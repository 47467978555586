import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-cover-story-navigation',
  templateUrl: './cover-story-navigation.component.html',
  styleUrls: ['./cover-story-navigation.component.scss']
})
export class CoverStoryNavigationComponent implements OnInit {
  currentUrl: string = '';
  slug: any;
  isBrowser: boolean = false;
  pageTitle: string = '';
  constructor(
    private location: Location,
    private titleService: Title,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.slug = this.location.path();
    this.currentUrl = `https://www.chaseyoursport.com${this.slug}`;
    setTimeout(() => {
      if (this.isBrowser) {
        this.pageTitle = this.titleService.getTitle();
      }
    }, 3000);
  }
}
