import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { SlugifyService } from '../services/slugify.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-navigation',
  templateUrl: './dashboard-navigation.component.html',
  styleUrls: ['./dashboard-navigation.component.scss']
})
export class DashboardNavigationComponent implements OnInit {
  text: string = '';
  sidebarVisible: boolean = false;
  isUserLoggedIn: boolean = false;
  userDetails: any;
  initials: string | null = null;
  dashboardUser: boolean = false;
  userRole: string = 'Reader';
  sidebarItems: any[] = [
    {
      title: 'Blog',
      open: false,
      links: [
        {
          label: 'Editor Panel',
          icon: 'pi pi-fw pi-pencil',
          route: '/dashboard/editor-panel'
        },
        {
          label: 'Admin Panel',
          icon: 'pi pi-fw pi-user',
          route: '/dashboard/admin-panel/published-blogs-list'
        },
        {
          label: 'Draft Panel',
          icon: 'pi pi-fw pi-file',
          route: '/dashboard/draft-panel/pending-blogs-list'
        }
      ]
    },
    {
      title: 'Brand Story',
      open: false,
      links: [
        {
          label: 'Create Brand Story',
          icon: 'pi pi-fw pi-pencil',
          route: '/dashboard/brand-story/create'
        },
        {
          label: 'Brand Story Panel',
          icon: 'pi pi-fw pi-user',
          route: '/dashboard/brand-story/list'
        }
      ]
    },
    {
      title: 'Poll',
      open: false,
      links: [
        {
          label: 'Create Poll',
          icon: 'pi pi-fw pi-pencil',
          route: '/dashboard/poll/create'
        },
        {
          label: 'Polls List',
          icon: 'pi pi-fw pi-bars',
          route: '/dashboard/poll/polls-list'
        }
      ]
    },
    {
      title: 'Utilities',
      open: false,
      links: [
        {
          label: 'Add Featured Segments',
          route: '/dashboard/utility/add-featured-segments'
        },
        {
          label: 'Advertisement Panel',
          route: '/dashboard/utility/advertisement-panel'
        },
        {
          label: 'De-Index Articles',
          route: '/dashboard/utility/de-indexing-articles-panel'
        },
        {
          label: 'De-Index Tags',
          route: '/dashboard/utility/de-indexing-tags-panel'
        },
        {
          label: 'Remove Tags',
          route: '/dashboard/utility/remove-tags'
        }
      ]
    }
  ];

  constructor(
    private slugifyService: SlugifyService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.checkUserLoggedIn();
  }
  navigate(route: string) {
    this.router.navigate([route]);
    // Close the sidebar if necessary
    this.sidebarVisible = false;
  }
  toggleSection(index: number): void {
    this.sidebarItems[index].open = !this.sidebarItems[index].open;
  }

  checkUserLoggedIn(): void {
    // Here you would check for the logged in state
    const token = localStorage.getItem('auth_token');
    this.isUserLoggedIn = !!token;
    if (this.isUserLoggedIn) {
      this.fetchUserDetails();
    }
  }

  signOut(): void {
    this.authService.deleteToken();
    location.reload();
  }

  fetchUserDetails() {
    this.authService.getUserDetails().subscribe({
      next: (response) => {
        if (response && response.data) {
          this.userDetails = response.data;
          this.dashboardUser = response.dashboardUser;
          this.userRole = response.userRole;
          this.initials = this.getInitials(this.userDetails.name);
          if (this.userRole === 'Admin') {
            this.sidebarItems.push({
              title: 'Reports',
              open: false,
              links: [
                {
                  label: 'Users List',
                  route: '/dashboard/report/users-list'
                },
                {
                  label: 'Subscribers List',
                  route: '/dashboard/report/subscribers-list'
                },
                {
                  label: 'Articles List',
                  route: '/dashboard/report/articles-list'
                },
                {
                  label: 'Contact List',
                  route: '/dashboard/report/contact-list'
                },
                {
                  label: 'Advertise List',
                  route: '/dashboard/report/advertise-list'
                }
              ]
            });
          }
        } else {
          console.error('No user details found');
        }
      },
      error: (error) => {
        console.error('Error fetching user details', error);
        this.authService.deleteToken();
        location.replace('/');
      }
    });
  }

  getInitials(name: string): string {
    return name
      .split(' ')
      .map((n) => n[0])
      .join('')
      .toUpperCase();
  }
  toggleSidebar(): void {
    this.sidebarVisible = !this.sidebarVisible;
  }
  slugify(input: string): string {
    return this.slugifyService.slugify(input);
  }
}
