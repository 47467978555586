import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  PLATFORM_ID,
  HostListener
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { HomeService } from './components/home/home.service';
import { Router, NavigationEnd, Event as RouterEvent } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isBrowser: boolean = true;
  showPopup: boolean = false;
  showFooter: boolean = true;
  isMobileDevice: boolean = false;
  popupAdDesktop: any = null;
  popupAdMobile: any = null;
  excludeRoutes: string[] = ['/dashboard', '/user', '/brand-story'];
  excludedRoutesFooter: string[] = ['/brand-story'];
  title = 'Chase Your Sport: Stay Updated on All Things Sports';
  private popupTimeout: any;
  private destroy$ = new Subject<void>();

  constructor(
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: Object,
    private homeService: HomeService,
    private router: Router
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      this.titleService.setTitle('Chase Your Sport');
    }
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.getBanners();
      this.updateDeviceType();
      this.handleNavigation();
      this.handleNavigationFooter();
    }
  }

  ngOnDestroy() {
    if (this.isBrowser) {
      this.destroy$.next();
      this.destroy$.complete();
      clearTimeout(this.popupTimeout);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (this.isBrowser) {
      this.updateDeviceType();
    }
  }

  private updateDeviceType(): void {
    const screenWidthThreshold = 768;
    this.isMobileDevice = window.innerWidth < screenWidthThreshold;
  }

  private handleNavigation(): void {
    this.router.events
      .pipe(
        filter(
          (event: RouterEvent): event is NavigationEnd =>
            event instanceof NavigationEnd
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((event: NavigationEnd) => {
        const isExcludedRoute = this.excludeRoutes.some((route) =>
          event.urlAfterRedirects.startsWith(route)
        );
        if (!isExcludedRoute) {
          this.decideToShowPopup();
        }
      });
  }

  private handleNavigationFooter(): void {
    this.router.events
      .pipe(
        filter(
          (event: RouterEvent): event is NavigationEnd =>
            event instanceof NavigationEnd
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((event: NavigationEnd) => {
        const isExcludedRoute = this.excludedRoutesFooter.some((route) =>
          event.urlAfterRedirects.startsWith(route)
        );
        if (!isExcludedRoute) {
          this.showFooter = true;
        } else {
          this.showFooter = false;
        }
      });
  }
  private decideToShowPopup(): void {
    const popupShownInfo = JSON.parse(
      localStorage.getItem('popupAlreadyShown') || '{}'
    );
    const now = new Date().getTime();

    // Show popup if never shown before or if it was shown more than 30 minutes ago
    if (
      !popupShownInfo.shown ||
      now - popupShownInfo.timestamp > 30 * 60 * 1000
    ) {
      this.showPopupAd();
    }
  }

  closePopup() {
    if (this.isBrowser) {
      this.showPopup = false;
    }
  }

  getBanners(): void {
    this.homeService.loadAllPageBannerData().subscribe({
      next: (res) => {
        res.data.forEach((banner: any) => {
          if (banner.banner_position === 'popup-adv') {
            if (banner.device === 'desktop') {
              this.popupAdDesktop = banner;
            } else if (banner.device === 'mobile') {
              this.popupAdMobile = banner;
            }
          }
        });
      },
      error: (err) => {
        console.error('Error fetching banners:', err);
      }
    });
  }

  showPopupAd(): void {
    const now = new Date().getTime();
    // Set the flag and timestamp in localStorage
    localStorage.setItem(
      'popupAlreadyShown',
      JSON.stringify({ shown: true, timestamp: now })
    );
    this.popupTimeout = setTimeout(() => {
      this.showPopup = true;
    }, 5000);
  }
}
