<div style="background-color: #333; padding: 20px; margin-top: 2rem">
  <div class="flex justify-content-center align-items-center">
    <p
      style="
        margin-left: 10px;
        color: white;
        font-weight: 600;
        font-size: 1.5rem;
      "
    >
      Chase Your Sport
    </p>
  </div>
  <div
    class="flex justify-content-center align-items-center"
    style="margin-bottom: 20px"
  >
    <p
      style="
        color: white;
        font-weight: 300;
        width: 80%;
        margin: 0 auto;
        text-align: center;
        line-height: 1.5rem;
      "
    >
      Stay up-to-date on the latest sports news, stats, expert analysis and
      trends, including cricket, football, wrestling, tennis, basketball,
      Formula One and more. Find previews, schedules, results of upcoming
      events, and fantasy tips on Chase Your Sport.
    </p>
  </div>

  <div
    class="flex justify-content-center align-items-center gap-4 flex-wrap mt-6"
    style="margin-bottom: 50px"
  >
    <a href="/about" class="link" target="_blank">About</a>
    <a href="/advertise" class="link" target="_blank">Advertise</a>
    <a href="/contact" class="link" target="_blank">Contact</a>
  </div>

  <div
    class="flex justify-content-center align-items-center gap-5"
    style="margin-bottom: 50px"
  >
    <a href="https://www.facebook.com/SportsSocialBlog" target="_blank"
      ><i class="pi pi-facebook" style="color: white"></i
    ></a>
    <a href="https://twitter.com/chaseyoursport" target="_blank"
      ><i class="pi pi-twitter" style="color: white"></i
    ></a>
    <a href="https://www.instagram.com/chaseyoursport/" target="_blank"
      ><i class="pi pi-instagram" style="color: white"></i
    ></a>
    <a href="https://in.pinterest.com/chaseyoursport1" target="_blank">
      <i class="fa-brands fa-pinterest" style="color: white"></i
    ></a>
  </div>

  <div class="flex justify-content-center align-items-center gap-5">
    <a href="/privacy-policy" class="link" target="_blank">Privacy Policy</a>
    <a href="/topic/explore-topics" class="link" target="_blank">Explore</a>
  </div>
</div>
