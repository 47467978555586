import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private backendUrl = environment.backendEndpoint;

  constructor(private http: HttpClient) {}

  // Example of a service method
  getTrendingTopics(): Observable<any> {
    return this.http.get(
      `${this.backendUrl}/redis/topic/getTrendingTopicsFromRedis`
    );
  }
}
