<div class="header-wrapper">
  <p-toolbar styleClass="custom-toolbar-dashboard">
    <div class="p-toolbar-group-left">
      <button (click)="sidebarVisible = true" aria-label="Open Sidebar">
        <i class="pi pi-bars"></i>
      </button>
      <div class="logo-container" routerLink="/">
        <img
          src="/assets/images/sports-social-logo.png"
          alt="Logo"
          class="logo"
        />
        <div class="logo-text">
          <div class="logo-title">CYS Dashboard</div>
          <div class="logo-subtitle">Sports Social Blog</div>
        </div>
      </div>
    </div>
    <div class="p-toolbar-group-right do-not-show-in-tab-and-mobile">
      <a
        pButton
        class="toolbar-buttons"
        routerLink="/login"
        *ngIf="!isUserLoggedIn"
      >
        <i class="pi pi-sign-in"></i> Sign In
      </a>
      <div
        class="user-avatar"
        *ngIf="isUserLoggedIn"
        (click)="op.toggle($event)"
      >
        <img
          *ngIf="
            userDetails?.profilePicture &&
            !userDetails.profilePicture.includes('prod.sportsocial.in')
          "
          [src]="userDetails.profilePicture"
          alt="{{ userDetails.name }}"
        />
        <div
          *ngIf="
            !userDetails?.profilePicture ||
            userDetails.profilePicture.includes('prod.sportsocial.in')
          "
          class="user-initials"
        >
          {{ initials }}
        </div>
      </div>
    </div>
  </p-toolbar>
</div>
<p-sidebar
  [(visible)]="sidebarVisible"
  position="left"
  styleClass="custom-sidebar"
  [modal]="false"
>
  <!-- Header with logo -->
  <div class="custom-sidebar-header">
    <img src="/assets/images/sports-social-logo.png" alt="Logo" class="logo" />
  </div>

  <!-- Scrollable content area -->
  <div class="sidebar-content">
    <div
      *ngFor="let item of sidebarItems; let i = index"
      class="sidebar-section"
    >
      <div class="section-title" (click)="toggleSection(i)">
        {{ item.title }}
        <i
          class="pi"
          [ngClass]="item.open ? 'pi-chevron-up' : 'pi-chevron-down'"
        ></i>
      </div>
      <ul *ngIf="item.open">
        <li *ngFor="let link of item.links" (click)="navigate(link.route)">
          <i [class]="link.icon"></i> {{ link.label }}
        </li>
      </ul>
    </div>
  </div>
</p-sidebar>

<!-- OverlayPanel component -->
<p-overlayPanel #op>
  <ul>
    <li>
      <a routerLink="/user/profile"><i class="pi pi-user"></i> Profile</a>
    </li>
    <li>
      <a routerLink="/user/create/article"><i class="pi pi-pencil"></i>Write</a>
    </li>
    <li>
      <a routerLink="/user/edit-profile"
        ><i class="pi pi-user-edit"></i>Edit Profile</a
      >
    </li>
    <!-- <li>
      <a href="/user/profile/stats"><i class="pi pi-chart-bar"></i>Stats</a>
    </li> -->
    <li *ngIf="userRole === 'Admin' || userRole === 'Editor'">
      <a routerLink="/dashboard"><i class="pi pi-briefcase"></i>Dashboard</a>
    </li>
    <!-- ... other list items ... -->
    <li>
      <button (click)="signOut()">
        <i class="pi pi pi-sign-out"></i>Sign out
      </button>
    </li>
  </ul>
</p-overlayPanel>
