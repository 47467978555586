<div [ngStyle]="{ display: isBrowser ? 'block' : 'none' }">
  <app-header-wrapper></app-header-wrapper>
  <router-outlet></router-outlet>
  <app-footer *ngIf="showFooter"></app-footer>
  <!-- Check if on a mobile device and if mobile ad content is available -->
  <ng-container *ngIf="isMobileDevice">
    <ng-container *ngIf="popupAdMobile && showPopup">
      <div class="popup-overlay">
        <div class="popup-advertisement">
          <div class="popup-content">
            <a [href]="popupAdMobile.action_button_link" target="_blank">
              <img
                [src]="popupAdMobile.banner_img"
                [alt]="popupAdMobile.banner_main_heading || 'Mobile Ad Image'"
              />
            </a>
            <button class="close-button" (click)="closePopup()">&times;</button>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <!-- Check if not on a mobile device and if desktop ad content is available -->
  <ng-container *ngIf="!isMobileDevice">
    <ng-container *ngIf="popupAdDesktop && showPopup">
      <div class="popup-overlay">
        <div class="popup-advertisement">
          <div class="popup-content">
            <a [href]="popupAdDesktop.action_button_link" target="_blank">
              <img
                [src]="popupAdDesktop.banner_img"
                [alt]="popupAdDesktop.banner_main_heading || 'Desktop Ad Image'"
              />
            </a>
            <button class="close-button" (click)="closePopup()">&times;</button>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
