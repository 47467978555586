import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule } from '@angular/forms';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { HeaderWrapperComponent } from './header-wrapper.component';
import { DashboardNavigationComponent } from './dashboard-navigation/dashboard-navigation.component';
import { CoverStoryNavigationComponent } from './cover-story-navigation/cover-story-navigation.component';
@NgModule({
  declarations: [
    NavigationComponent,
    FooterComponent,
    HeaderWrapperComponent,
    DashboardNavigationComponent,
    CoverStoryNavigationComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    ButtonModule,
    FormsModule,
    SidebarModule,
    ToolbarModule,
    MenuModule,
    MenubarModule,
    OverlayPanelModule
  ],
  exports: [NavigationComponent, FooterComponent, HeaderWrapperComponent],
  providers: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
