import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private backendUrl = environment.backendEndpoint;

  constructor(private http: HttpClient) {}

  isLoggedIn(): boolean {
    const token = localStorage.getItem('auth_token'); // Or your token storage strategy
    return !!token; // Returns true if token exists, meaning user is logged in
  }

  registerUser(userData: {
    email: string;
    name: string;
    userName?: string;
    userRoleId?: number;
  }): Observable<any> {
    return this.http.post(`${this.backendUrl}/auth/registerUser`, userData);
  }
  storeToken(token: string): void {
    localStorage.setItem('auth_token', token);
  }

  getToken(): string | null {
    return localStorage.getItem('auth_token');
  }

  deleteToken(): void {
    localStorage.removeItem('auth_token');
  }

  // Use this method to set headers for authenticated requests
  getAuthHeaders() {
    const token = this.getToken();
    return {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  }
  getUserDetails(): Observable<any> {
    const token = this.getToken(); // Assuming you have a method to get the stored token
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get(`${this.backendUrl}/auth/getUserDetails`, { headers });
  }

  sendVerificationCode(body: { email: string }): Observable<any> {
    return this.http.post(
      `${this.backendUrl}/email/loginVerificationCode`,
      body
    );
  }
  login(body: { email: string; code: string }): Observable<any> {
    return this.http.post(`${this.backendUrl}/auth/login`, body);
  }
  isDashboardUser(): Observable<any> {
    const token = this.getToken(); // Assuming you have a method to get the stored token
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get(`${this.backendUrl}/auth/isDashboardUser`, {
      headers
    });
  }
}
