import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private backendUrl = environment.backendEndpoint;

  constructor(private http: HttpClient) {}

  getStartingFiveBlogs(): Observable<any> {
    return this.http.get(
      `${this.backendUrl}/redis/blog/getStartingTenBlogsFromRedis`
    );
  }

  getTopTopicsLatestBlog(): Observable<any> {
    return this.http.get(
      `${this.backendUrl}/redis/blog/loadKeywordsBlogDataPagination`
    );
  }

  getFeaturedArticlesHomePage(): Observable<any> {
    return this.http.get(
      `${this.backendUrl}/redis/blog/featuredArticlesHomePage`
    );
  }
  loadHomePageBannerData(): Observable<any> {
    return this.http.get(`${this.backendUrl}/banner/loadHomePageBlogBanner`);
  }
  loadAllPageBannerData(): Observable<any> {
    return this.http.get(`${this.backendUrl}/banner/loadAllPagesBlogBanner`);
  }
}
