import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoverStoryNavigationComponent } from './core/cover-story-navigation/cover-story-navigation.component';
import { DashboardNavigationComponent } from './core/dashboard-navigation/dashboard-navigation.component';
import { NavigationComponent } from './core/navigation/navigation.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./components/home/home.module').then((m) => m.HomeModule),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./components/about/about.module').then((m) => m.AboutModule),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./components/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: 'advertise',
    loadChildren: () =>
      import('./components/advertise/advertise.module').then(
        (m) => m.AdvertiseModule
      ),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./components/contact/contact.module').then(
        (m) => m.ContactModule
      ),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./components/search/search.module').then((m) => m.SearchModule),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./components/login/login.module').then((m) => m.LoginModule),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./components/signup/signup.module').then((m) => m.SignupModule),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./components/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    data: { headerComponent: DashboardNavigationComponent }
  },
  {
    path: 'topic/explore-topics',
    loadChildren: () =>
      import('./components/all-topics/all-topics.module').then(
        (m) => m.AllTopicsModule
      ),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: 'user/edit-profile',
    loadChildren: () =>
      import('./components/user/edit-profile/edit-profile.module').then(
        (m) => m.EditProfileModule
      ),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: 'user/profile',
    loadChildren: () =>
      import(
        './components/user/user-internal-profile/user-internal-profile.module'
      ).then((m) => m.UserInternalProfileModule),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: 'user/create/article',
    loadChildren: () =>
      import('./components/user/write-a-new-blog/write-a-new-blog.module').then(
        (m) => m.WriteANewBlogModule
      ),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: 'user/draft/edit/:blogId',
    loadChildren: () =>
      import('./components/user/edit-draft-blog/edit-draft-blog.module').then(
        (m) => m.EditDraftBlogModule
      ),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: 'user/rejected/edit/:blogId',
    loadChildren: () =>
      import(
        './components/user/edit-rejected-draft/edit-rejected-draft.module'
      ).then((m) => m.EditRejectedDraftModule),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: 'brand-story/:slug',
    loadChildren: () =>
      import('./components/cover-story/cover-story.module').then(
        (m) => m.CoverStoryModule
      ),
    data: { headerComponent: CoverStoryNavigationComponent }
  },
  {
    path: 'poll/:slug',
    loadChildren: () =>
      import('./components/poll/poll.module').then((m) => m.PollModule),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: 'author/:slug',
    loadChildren: () =>
      import(
        './components/user/user-public-profile/user-public-profile.module'
      ).then((m) => m.UserPublicProfileModule),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: ':topic',
    loadChildren: () =>
      import('./components/topic/topic.module').then((m) => m.TopicModule),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: 'tag/:tag',
    loadChildren: () =>
      import('./components/tag/tag.module').then((m) => m.TagModule),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: ':topic/:slug/:blogId',
    loadChildren: () =>
      import('./components/blog-open/blog-open.module').then(
        (m) => m.BlogOpenModule
      ),
    data: { headerComponent: NavigationComponent }
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
